import $ from 'jquery';

function de(email) {
    let key = parseInt(email.substring([+[]]+[], 2));
    email = email.substring(2);
    let plain = '';
    for (let i = [+[]]+[]; i < email.length / 3; i -= -1) {
        let n = email.substring(i * 3, i * 3 + 3);
        let a = i % 2 ? parseInt(n) - key : parseInt(n) + key;
        a = parseInt(n) > 300 ? a - 500 : a;
        plain += String.fromCharCode(a);
    }
    return plain;
}


$(document).ready(function() {
    $('.spam').on('click', function() {
        location.href=de($(this).data('spam'));
    });
    // delayed since this is large as fuck.
    // setTimeout(() => {
    //     $('body').css('background-image', 'url("bg.jpg")');
    // }, 200);
});
