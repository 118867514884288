import $ from 'jquery';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEnvelopeSquare, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';


$(document).ready(function() {
    $('#linkTel').html(icon(faPhoneSquare).html);
    $('#linkMail').html(icon(faEnvelopeSquare).html);
    $('#linkFacebook').html(icon(faFacebookSquare).html);
    $('.fa-check').html(icon(faCheck).html);
});