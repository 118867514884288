/* global loadingComp */

// 'use strict';

import {html} from 'lit-element';
import {BaseComp} from './base.js';

class Baustelle extends BaseComp {

    static get properties() {
        return {};
    }

    constructor() {
        super();
        loadingComp.close();
    }

    render() {
        return html`
        <div class="col-12 p-0">
        <div class="text-center">
            <br>
            <h1>Diese Seite befindet sich<br>gerade in der Werkstatt</h1>
        </div>
        </div>`;
    }

}

customElements.define('img-comp', Baustelle);
