/* global loadingComp */


import {html} from 'lit-element';
import {BaseComp} from './base.js';


class Datenschutz extends BaseComp {
    render() {
        loadingComp.close();

        return html`
        <div class="box w-100">
        <h1>Datenschutz</h1>
        BlaBlaBla
        </div>
      `;
    }
}
customElements.define('page-datenschutz', Datenschutz);

class Impressum extends BaseComp {
    render() {
        loadingComp.close();

        return html`
        <div class="box w-100">
        <h1>Impressum</h1>
        BlaBlaBla
        </div>
      `;
    }
}
customElements.define('page-impressum', Impressum);