import {html} from 'lit-element';
import Navigo from 'navigo';
import {BaseComp} from './base.js';

class Router extends BaseComp {
    static get properties() {
        return {
            route: { type: Object }
        };
    }
    constructor() {
        super();
        window.router = new Navigo(null, true, '#!');
        window.router.on('/datenschutz', () => {
            this.route = html`<page-datenschutz class="row"></page-datenschutz>`;
        }).on('/impressum', () => {
            this.route = html`<page-impressum class="row"></page-impressum>`;
        }).on('*', () => {
            this.route = html`<img-comp class="row"></img-comp>`;
        });
        window.router.resolve();
    }
    render() {
        return this.route;
    }
}
customElements.define('path-router', Router);